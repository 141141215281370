@font-face {
  font-family: 'PingFang-SC-Regular';
  src: url('/assets/fonts/pingfangscregular_downcc/PingFang Regular.otf') format('otf'), /* 支持现代浏览器 */;
}
@font-face {
  font-family: 'PingFang-SC-Bold';
  src: url('/assets/fonts/pingfangscbold_downcc/pingfangscbold_downcc.ttf') format('ttf'), /* 支持现代浏览器 */;
}
@font-face {
  font-family: 'Helvetica-Light';
  src: url('/assets/fonts/helvetica_downcc/helvetica-light.otf') format('ttf'), /* 支持现代浏览器 */;
}
@font-face {
  font-family: 'PingFang SC-Light';
  src: url('/assets/fonts/pingfangscmedium_downcc/PingFang Medium_downcc.otf') format('ttf'), /* 支持现代浏览器 */;
}
body {
  font-family: 'PingFang-SC-Regular', "Arial";
}
.indexResponse_section_1__g6BUm {
  position: fixed;
  z-index: 1;
  background: rgba(255, 255, 255, 0.88) !important;
}
.indexResponse_section_9__3IxsO {
  margin-left: 60px;
}
a:active {
  color: #333;
}
a:-webkit-any-link {
  text-decoration: none !important;
}
.indexResponse_box_3__T9fAr,
.indexResponse_group_4__CDFXq {
  display: none !important;
}
.indexResponse_box_19__QnBIi {
  justify-content: space-between;
  overflow: visible;
}
.indexResponse_image-wrapper_4__vVklQ {
  overflow: visible;
}
.indexResponse_image-wrapper_4__vVklQ img {
  width: 100% !important;
}
.indexResponse_image_6__1q8nq,
.indexResponse_image_4__2yw9A,
.indexResponse_image_4__Ncc3I,
.indexResponse_image_4__3MqGs {
  width: 0.6vw !important;
}
.indexResponse_box_21__22SLV {
  margin: 0 !important;
  padding: 20px;
  box-sizing: content-box;
}
.justify-between {
  align-items: center !important;
}
.indexResponse_text_12__2SDqp {
  margin-top: 0 !important;
}
.indexResponse_group_3__1w3uk,
.indexResponse_group_2__2ni8Y,
.indexResponse_group_2__1e-Kt,
.indexResponse_group_3__30tu0 {
  display: none !important;
}
.indexResponse_block_10__39Zsz,
.indexResponse_section_1__2gaww,
.indexResponse_group_24__1W766,
.indexResponse_box_20__1VkIY {
  border: 1px solid #45A6ff !important;
}
.indexResponse_block_2__1yJgF,
.indexResponse_group_12__JsAyW,
.indexResponse_section_3__2Gvv1,
.indexResponse_box_2__8d_Sh,
.indexResponse_section_5__1POZ-,
.indexResponse_block_10__39Zsz,
.indexResponse_section_16__3H9PG,
.indexResponse_section_5__3ffSf,
.indexResponse_section_13__b6Ts0,
.indexResponse_section_9__3IxsO,
.indexResponse_text_1__3KntK,
.indexResponse_group_12__J {
  overflow: visible;
}
.indexResponse_box_2__8d_Sh {
  width: auto !important;
}
.indexResponse_group_12__JsAyW,
.indexResponse_text_13__1cRiF {
  margin-top: 0 !important;
}
.indexResponse_box_1__YHdN8 {
  height: 6.05vw !important;
  width: 7vw !important;
  overflow: visible;
}
.indexResponse_image_1__2f7wk {
  width: 7vw !important;
  height: auto !important;
}
.indexResponse_paragraph_2__2B6dN {
  margin: 8vw 0 0 10.67vw !important;
}
.indexResponse_text_11__1QV02 {
  margin: 21.68vw 0 0 -23.75vw !important;
  font-size: 1.2vw !important;
}
.indexResponse_group_22__25cCy {
  margin: 8.91vw 0 0 5.93vw !important;
}
.indexResponse_group_24__1W766 {
  top: 13.9vw !important;
}
.indexResponse_text_25__9kteb {
  margin: 5.14vw 0 0 10.67vw !important;
}
.indexResponse_text_11__1Qle_ {
  margin-top: 2.11vw !important;
  font-size: 1.2vw !important;
}
.indexResponse_box_20__1VkIY {
  top: 15.08vw !important;
  height: 19vw !important;
}
.indexResponse_text_26__37p-V {
  margin: 1.28vw 0 0 2.08vw !important;
  font-size: 1.3vw !important;
}
.indexResponse_section_2__233H5,
.indexResponse_box_21__anOYa,
.indexResponse_section_3__2pGJz,
.indexResponse_block_6__2MkZ8,
.indexResponse_block_7__10xz0 {
  margin-top: 0 !important;
}
.indexResponse_text-group_7__1mHXq,
.indexResponse_text-group_8__34PWt,
.indexResponse_text-group_9__2KVVk,
.indexResponse_text-group_10__1BJDK,
.indexResponse_text-group_11__1E-4s {
  font-size: 1.2vw !important;
}
.indexResponse_section_1__2gaww {
  left: -46vw !important;
  top: 17.21vw !important;
}
.indexResponse_text-wrapper_24__MDwfc {
  margin: 6.95vw 0 0 10.62vw !important;
}
.indexResponse_image_4__3MqGs,
.indexResponse_image_4__2yw9A {
  margin: 6.43vw 0 6.51vw 38.75vw !important;
}
.indexResponse_group_11__1IbSa {
  width: 89.16vw !important;
}
.indexResponse_page__22n8A {
  height: auto !important;
}
.indexResponse_section_5__1POZ- {
  position: relative !important;
  top: 0 !important;
}
.indexResponse_box_12__29QMj {
  box-shadow: none !important;
  overflow: visible !important;
}
.indexResponse_section_9__3IxsO a.active {
  color: #4D6BFE !important;
}
.indexResponse_text-wrapper_26__2qGaa {
  margin-left: 4.16vw !important;
}
.indexResponse_section_8__CEYtV {
  width: 2.15vw !important;
  position: absolute !important;
  left: 0 !important;
  margin-top: -1.1vw;
}
.indexResponse_section_6__Rgrlh.justify-between {
  overflow: visible;
  align-items: flex-end !important;
}
.indexResponse_text_7__xYxH2,
.indexResponse_text_3__32nG-,
.indexResponse_text_4__1Uozq,
.indexResponse_text_7__2-zwz,
.indexResponse_text_4__1_Xnh {
  transform: rotate(90deg);
  width: auto !important;
  margin-top: 5vw !important;
  white-space: nowrap;
}
.indexResponse_section_7__1pniE {
  margin-top: 4.45vw !important;
  overflow: visible !important;
}
.indexResponse_image-text_7__3KsZj {
  margin-top: 3.11vw !important;
}
.indexResponse_box_19__QnBIi {
  width: 91.61vw !important;
}
.indexResponse_section_6__3tUq7 {
  width: 63.75vw !important;
}
.indexResponse_text_3__32nG- {
  margin-left: -1.7vw !important;
  margin-top: 1vw !important;
}
.indexResponse_group_11__1IbSa {
  overflow: visible;
}
.indexResponse_text_4__1Uozq {
  margin-top: 10vw !important;
  margin-left: 1vw !important;
}
.indexResponse_text_7__2-zwz {
  margin-left: 0 !important;
  position: absolute;
  right: 4vw !important;
  margin-top: 8vw !important;
}
.arrow_up {
  position: absolute;
  right: 4vw;
  background: url(./img/line.png) no-repeat;
  width: 0.6vw;
  height: 7.35vw;
  background-size: contain;
  bottom: -5vw;
  z-index: 1;
}
.indexResponse_page__22n8A {
  overflow: visible !important;
}
.indexResponse_section_1__2jgJX {
  width: 13.93vw !important;
  height: 5.41vw !important;
  background-position: 0 0 !important;
  background-size: 100% !important;
  justify-content: center !important;
  align-items: center !important;
}
.indexResponse_image-text_8__F_Onh {
  margin: 0 0 0 0 !important;
}
.indexResponse_box_3__1OIRf,
.indexResponse_box_4__3shGY,
.indexResponse_list-items_1-0__CozHx,
.indexResponse_list-items_1-1__2NviJ,
.indexResponse_list-items_1-2__360nA,
.indexResponse_box_6__1JAFq {
  height: 20.7vw !important;
  width: 13.63vw !important;
}
.indexResponse_list-items_1-0__CozHx {
  margin-right: 1.5vw !important;
}
.indexResponse_text_2__1fna5,
.indexResponse_text_3__2zJ4z,
.indexResponse_text_4-0__f8eG-,
.indexResponse_text_4-1__7l-uA,
.indexResponse_text_4-2__26MAT {
  margin: 9.78vw 0 0 0 !important;
  text-align: center !important;
}
.indexResponse_box_22__1j5c4 {
  height: 38.25vw !important;
}
.indexResponse_text_1__3KntK {
  position: relative;
  margin-left: 20.65vw;
}
.indexResponse_paragraph_1__26vm9,
.indexResponse_text_25__9kteb,
.indexResponse_paragraph_2__2B6dN,
.indexResponse_text_1__2zmYt {
  font-weight: 600;
  overflow: visible;
}
.indexResponse_text_1__2zmYt {
  margin-top: 6.12vw !important;
}
.indexResponse_box_3__1tU-N,
.indexResponse_group_26__1mw-b {
  margin-left: 2.5vw !important;
  margin-top: -5vw !important;
  overflow: visible !important;
}
.indexResponse_group_1__3G7kq {
  overflow: visible;
}
.indexResponse_box_12__29QMj {
  left: 0 !important;
  margin-left: 2.4vw !important;
}
.indexResponse_block_9__21K3g {
  opacity: 0 !important;
}
.indexResponse_box_5__1yOvY,
.indexResponse_section_1__2vqlT {
  background-color: transparent !important;
}
.indexResponse_box_6__1JAFq {
  background-image: none !important;
}
.imgscroll_wrap {
  overflow-x: auto;
}
.indexResponse_list_3__2AOqN {
  height: 21.7vw !important;
  width: 88.22vw !important;
  margin-top: 2.9vw !important;
}
.indexResponse_list_3__2AOqN::-webkit-scrollbar,
.indexResponse_section_6__3tUq7::-webkit-scrollbar {
  /* 竖直滚动条宽度 */
  height: 4px;
}
.indexResponse_text-wrapper_3-0__ai8yf {
  height: 20.7vw !important;
  width: 13.63vw !important;
  align-items: center;
}
.indexResponse_list_3__2AOqN::-webkit-scrollbar-track,
.indexResponse_section_6__3tUq7::-webkit-scrollbar-track {
  background-color: transparent;
}
.indexResponse_box_5__1yOvY {
  position: absolute;
  right: 4vw;
  margin-top: 11.4vw !important;
}
.indexResponse_image_4__U-5XX {
  cursor: pointer;
}
.indexResponse_section_9__3IxsO a:hover {
  color: #356FFF;
}
/* 国家 */
.countryResponse_group_9__3jOrw.justify-between {
  align-items: flex-start ! important;
  overflow: visible !important;
  height: auto !important;
}
.countryResponse_group_9__3jOrw {
  margin-top: 7.08vw !important;
}
a:-webkit-any-link {
  color: #333;
}
.indexResponse_text_2__2IG7N {
  overflow: visible;
  position: relative;
}
.indexResponse_text_2__2IG7N .indexResponse_section_8__CEYtV {
  width: 3.65vw !important;
}
.indexResponse_text_2__2IG7N .indexResponse_box_1__1FnRE {
  width: 5.75vw;
}
.indexResponse_group_8__33Fga {
  margin-top: 2.6rem;
}
.indexResponse_group_2-0__IuCuN,
.indexResponse_group_2-1__1ChJu,
.indexResponse_group_2-2__3jQPE {
  background-size: contain !important;
}
.indexResponse_list-items_1-0__2LkCc,
.indexResponse_list-items_1-1__2CuqK,
.indexResponse_list-items_1-2__2e5eM {
  background-repeat: no-repeat !important;
  background-size: contain !important;
}
.indexResponse_box_6__3mdl5,
.indexResponse_block_4__3XObX,
.indexResponse_group_3__29kJI,
.indexResponse_block_5__1aIg8 {
  margin-top: 0 !important;
}
.indexResponse_section_12__2lWgb {
  align-items: flex-start !important;
}
.indexResponse_section_4__8MOlk {
  background-repeat: no-repeat;
  background-size: contain;
}
.indexResponse_image-text_27-2__2jc_t {
  margin: 0.88vw 0 0 1.3vw !important;
}
.indexResponse_group_8__33Fga {
  margin-bottom: 0 !important;
}
.countryResponse_group_8__1T4YJ {
  overflow: visible;
}
.countryMenuResponse_text_7__1P9jR {
  width: 6.25vw;
  height: 1.57vw;
  overflow-wrap: break-word;
  color: #334155 !important;
  font-size: 1.56vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: right;
  white-space: nowrap;
  line-height: 1.57vw;
  margin: 1.3vw 0 0 7.5vw;
  text-align: center !important;
}
.countryMenuResponse_text_7__1P9jR.active {
  color: #4D6BFE !important;
}
.countryMenuResponse_text-wrapper_1__1fOTp {
  cursor: pointer;
}
.countryMenuResponse_section_10__RaVKI {
  position: fixed;
}
.countryMenuResponse_section_10__RaVKI > div {
  cursor: pointer;
}
.countryMenuResponse_section_10__RaVKI > div.active {
  border: 1px solid #45A6FF !important;
}
.countryMenuResponse_section_10__RaVKI > div.active span {
  color: #4D6BFE !important;
}
.countryMenuResponse_section_10__RaVKI > div:hover span.countryMenuResponse_text_7__1P9jR {
  color: #4D6BFE !important;
}
.countryMenuResponse_text-wrapper_1__1fOTp {
  border: 1px solid #93a3b8 !important;
}
.align-items-center {
  align-items: center !important;
}
.marginLeft-0 {
  margin-left: 0 !important;
}
.alignCenter {
  text-align: center !important;
}
.indexResponse_text-wrapper_7__14hmd {
  background: none !important;
  width: 21.64vw !important;
}
.indexResponse_text-wrapper_8__pfYlf {
  background: none !important;
}
.indexResponse_text-wrapper_9__1-JLG {
  background: none !important;
}
.indexResponse_text_15__1ciwB,
.indexResponse_paragraph_3__1bb8t,
.indexResponse_text_16__2EEhn {
  position: relative;
  overflow: visible;
}
.indexResponse_text_15__1ciwB::after,
.indexResponse_paragraph_3__1bb8t::after,
.indexResponse_text_16__2EEhn::after {
  content: "";
  width: 60px;
  height: 8px;
  background: linear-gradient(90deg, #45A6FF 0%, #356FFF 100%);
  border-radius: 4px;
  position: absolute;
  bottom: -20px;
  left: 50%;
  margin-left: -30px;
}
.countryResponse_group_8__1T4YJ {
  height: auto !important;
}
.indexResponse_text-wrapper_10__DwpF9,
.indexResponse_text-wrapper_13-0__1Gbte,
.indexResponse_text-wrapper_11__mkUsQ,
.indexResponse_text-wrapper_12__1Hdmh,
.indexResponse_text-wrapper_13-1__2hqJC,
.indexResponse_text-wrapper_13-2__tq2kD,
.indexResponse_text-wrapper_14__3Pist,
.indexResponse_text-wrapper_15__AJxgc,
.indexResponse_text-wrapper_16__kvr_y {
  background-repeat: no-repeat !important;
  background-position: center 30% !important;
  background-size: contain !important;
}
.indexResponse_box_6__2xis9 {
  background: none !important;
  height: auto !important;
}
.indexResponse_section_11__3Jzl8,
.indexResponse_section_5__2zolh {
  border: none !important;
}
.indexResponse_text-group_12__2lcYt.justify-between,
.indexResponse_text-group_21__Egfos.justify-between,
.indexResponse_text-group_11__1ikns.justify-between {
  align-items: flex-start !important;
}
.indexResponse_text_13__1awIP {
  overflow: visible !important;
}
.indexResponse_group_4__pMj-z {
  border-radius: 2.5vw !important;
}
.indexResponse_box_12__1amav {
  border: 0.3vw solid #356FFF !important;
}
.visible {
  overflow: visible !important;
}
.overhide {
  overflow: hidden !important;
}
.bold {
  font-weight: bold !important;
}
.page-prev {
  background: url(./img/icon_pag_1.png) no-repeat;
  width: 2vw;
  height: 2vw;
  background-size: contain;
  cursor: pointer;
}
.page-next {
  background: url(./img/icon_pag_2.png) no-repeat;
  width: 2vw;
  height: 2vw;
  background-size: contain;
  cursor: pointer;
}
.indexResponse_section_9__1Wpyf {
  height: 8.98vw !important;
  margin-left: 0 !important;
}
.pageArrowWrap {
  display: flex;
  justify-content: flex-end;
  grid-gap: 11px;
  gap: 11px;
}
.slidewrap {
  width: 63.75vw;
  margin-left: 2.29vw;
}
.picslidewrap2 {
  width: 63.75vw;
  margin-left: 2.29vw;
}
.picslidewrap3 {
  width: 82.22vw;
  margin-left: 3.33vw;
}
.transition {
  transition: all 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.indexResponse_section_6__3tUq7 {
  height: 19.5vw !important;
  margin-left: 0 !important;
}
.indexResponse_text_16__2EEhn {
  text-align: center !important;
}
.indexResponse_text_17__3eJjM {
  margin-top: 2.22vw !important;
}
.indexResponse_box_13__RXSEp {
  margin-top: 2.22vw !important;
}
.countryMenuResponse_text-wrapper_1__1fOTp {
  border-radius: 40px;
  height: 4.17vw;
  border: 1px solid;
  width: 21.25vw;
}
.linkTomore {
  font-size: 1.25vw;
  margin-right: 3.5vw;
  border-radius: 32px;
  width: 11.93vw;
  height: 3.34vw;
  border: 1px solid #475569;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 8.12vw;
  color: #334155;
}
.indexResponse_text_3__Rp2VCd {
  width: 5.47vw;
  height: 0.94vw;
  overflow-wrap: break-word;
  color: #4d6bfe;
  font-size: 0.93vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: center;
  white-space: nowrap;
  line-height: 0.94vw;
  margin-left: 2.87vw;
  margin-top: 1.2vw;
  z-index: 11;
}
.hidden {
  opacity: 0 !important;
}
.menuitem {
  position: relative;
  overflow: visible;
}
.menu-child {
  background: white;
  color: #333;
  transition: all 0.2s;
  overflow: hidden;
  z-index: -1;
  display: none;
  position: absolute;
  bottom: -1;
  left: 50%;
  box-shadow: 0 3px 12px rgba(51, 51, 51, 0.1);
  z-index: 11;
  margin-left: -5vw;
  border-radius: 0 0 15px 15px;
  width: 10vw;
}
.menuitem:hover .menu-child {
  z-index: 1000;
  opacity: 1;
  display: block;
}
.menu-child .menu-child-item:hover {
  background: #eee;
  color: #356FFF;
}
.menu-child .menu-child-item {
  height: 3.5vw;
  width: 100%;
  font-family: PingFangSC-Regular;
  white-space: nowrap;
  cursor: pointer;
}
.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.indexResponse_text_4__14rIE {
  overflow: visible;
  position: relative;
}
.indexResponse_text_4__14rIE .indexResponse_section_8__CEYtV {
  width: 3.65vw !important;
}
/* 选择大学 */
.dv_list_item_wrap {
  width: 91.67vw;
  margin: 1.08vw 0 0 4.16vw;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2vw;
  gap: 2vw;
}
.dv_list_item_wrap .dv_unvierst_item {
  box-shadow: rgba(204, 204, 204, 0.3) 0px 0px 10px;
  background: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 0.5vw;
  position: relative;
  background: #f3f3f3;
}
.dv_list_item_wrap .dv_unvierst_item .dv_bg_guojia {
  background: white;
  min-height: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dv_list_item_wrap .dv_unvierst_item .dv_bg_guojia img {
  height: 7.5vw;
  width: auto;
  max-width: 100%;
}
.dv_list_item_wrap .dv_unvierst_item .dv_item_wrapintroud {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0px;
  padding: 0.675rem;
  background: #f3f3f3;
  min-height: 10.6vw;
}
.dv_list_item_wrap .dv_unvierst_item .dv_item_wrapintroud .text_itemname {
  font-size: 1.04vw;
  line-height: 1.04vw;
  color: #334155;
  font-weight: bold;
  margin-bottom: 0.6vw;
}
.dv_list_item_wrap .dv_unvierst_item .dv_item_wrapintroud .dv_intoudction {
  font-size: 1.04vw;
  line-height: 1.54vw;
  color: #334155;
  margin-bottom: 0.6vw;
}
.dv_list_item_wrap .dv_unvierst_item .country-pill_pill__oEbGd {
  box-sizing: border-box;
  height: 17px;
  min-width: 46px;
  padding-right: 4px;
  background-color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #cccccc;
  border-image: initial;
  border-radius: 12.5px;
  display: flex;
  justify-content: space-around;
}
.align-items-end {
  align-items: flex-end !important;
}
.justify-items-end {
  justify-content: flex-end !important;
}
.university-card_uni_card_top__eMRzY {
  position: absolute;
  height: 0.5vw;
  width: 100%;
  z-index: 0;
  border-radius: 0.3vw 0.3vw 0 0;
}
.country-pill_pill_label__AzqRb {
  font-size: 0.7vw;
  margin-left: 0.3vw;
}
.dv_main_contentwrap {
  margin-bottom: 5vw;
}
.mt-auto {
  position: absolute;
  bottom: 13px;
  right: 13px;
}
.universities-filter_filter_buttons__dsksN {
  display: inline-flex;
  width: 100%;
  margin-left: 4.16vw;
  margin-top: 4vw;
}
.universities-filter_filter_buttons__dsksN .filter_filter_by__Tttrf {
  font-weight: 700;
  font-size: 1.04vw;
  color: #334155;
  min-width: 4vw;
}
.universities-filter_filter_buttons__dsksN .dv_select_country_wrap {
  border: 1px solid #4D6BFE;
  border-radius: 0.3vw;
  display: flex;
  padding-left: 0;
  border-right: none;
}
.universities-filter_filter_buttons__dsksN .dv_select_country_wrap li {
  padding: 10px;
  color: #4D6BFE;
  font-size: 14px;
  border-right: 1px solid #4D6BFE;
}
.universities-filter_filter_buttons__dsksN .dv_select_country_wrap li:hover {
  background-color: rgba(0, 0, 0, 0.04);
  box-shadow: 0 -2px 0 0 #4D6BFE inset;
  color: #4D6BFE;
}
.universities-filter_filter_buttons__dsksN li.choose {
  background-color: #4D6BFE;
  color: white;
}
.dv_ico_enter {
  background: url(./img/ico-enter.png) no-repeat !important;
  width: 1.05vw;
  height: 1.05vw;
  background-size: contain !important;
  margin-top: 0.31vw !important;
  margin-left: 1.77vw !important;
}
.indexResponse_image_7__-HRs9 {
  top: 10.69vw !important;
  width: 29.05vw !important;
  height: auto !important;
  object-fit: contain;
}
.indexResponse_group_25__1ReLw {
  width: 100% !important;
  height: 100% !important;
  background-size: cover !important;
}
.indexResponse_section_11__1lMfn,
.indexResponse_section_12__209Gs,
.indexResponse_section_14__2OQEx {
  overflow: visible !important;
}
.indexResponse_section_12__209Gs {
  margin-top: -3.84vw !important;
}
.indexResponse_text_11__2RuIl {
  font-size: 1.2vw !important;
}
.indexResponse_text_12__2SDqp,
.indexResponse_text_12__k_6AU,
.indexResponse_text_13__1cRiF {
  font-size: 1.3vw !important;
}
.indexResponse_image-text_9__1oouy,
.indexResponse_image-text_10__fUEYL,
.indexResponse_image-text_11__1C2XF,
.indexResponse_image-text_12__3FibI,
.indexResponse_image-text_10__fUEYL {
  margin-top: 0.4vw !important;
}
.indexResponse_image_1__3pGes,
.indexResponse_image_6__14Ygl {
  width: 7vw !important;
  height: auto !important;
}
.indexResponse_group_14__2cKHC,
.indexResponse_group_15__1jrFo,
.indexResponse_group_17__3qdqx,
.indexResponse_section_3__2Gvv1 {
  background: none !important;
}
.list_bg_1 {
  background: url(./img/lundon.PNG) !important;
  background-size: 100% auto !important;
}
.list_bg_2 {
  background: url(./img/pudu.PNG) !important;
  background-size: 100% auto !important;
}
.list_bg_3 {
  background: url(./img/moerben.PNG) !important;
  background-size: 100% auto !important;
}
.list_bg_4 {
  background: url(./img/keyan.PNG) !important;
  background-size: 100% auto !important;
}
.list_bg_5 {
  background: url(./img/hongk.PNG) !important;
  background-size: 100% auto !important;
}
.list_bg_6 {
  background: url(./img/zaodaotian.PNG) !important;
  background-size: 100% auto !important;
}
.indexResponse_section_5__1POZ-,
.indexResponse_block_3__gmmLp,
.indexResponse_box_5__qaFqp,
.indexResponse_box_2__8d_Sh {
  width: 100% !important;
}
.dv_footer_bar_scroll::-webkit-scrollbar {
  width: 0 !important;
}
.dv_fixed_menu_wrap {
  position: fixed;
}
.dv_countryMenu_wrap {
  width: 21.25vw;
  height: 31.25vw;
}
.countryResponse_group_8__1T4YJ {
  margin-bottom: 5.06vw !important;
}
.indexResponse_text-group_2__2aKvH {
  margin-top: 0 !important;
}
.indexResponse_text_6__2vio4 {
  text-align: left !important;
}
